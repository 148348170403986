// ========================================
//  aem scss templates
//      ver 2.0.3
//  初期状態を見やすくするためのSCSSです。
//  本サイトではCSSとともに削除してください。
// ======================================== 
@charset 'UTF-8';
// INDEX
// - common
// - text
// - accordion
// - head
// - header
// - form
// - support
// - login
// - register
@import "common/_reset.scss";

.wrapper {
    width: 80%;
    margin: 50px auto 0;
}

h1 {
    font-size: 1.5rem;
    text-align: center;
}

ul {
    text-align: center;
    li {
        display: inline-block;
    }
}

footer {
    width: 100%;
    text-align: center;
}